<template>
  <div>
    <div class="full">
      <div class="full">
        <div class="mainheader">
          <div style="display: flex; justify-content: space-between">
            <div class="half">
              Lohndaten
            </div>
            <div style="color:var(--ml); font-size: 14px; display: flex; align-items: flex-end;">
              <small class="text-secondary" style="padding-right: 4px">Gleiche Lohnarten zusammenfassen</small>
              <i class="fas fa-check-circle checkbox" :key="'cb-csc'" style="padding-bottom: 1px"
                 v-bind:class="{ false: !combineSameWagetypes, disabled: editCostCenter }"
                 v-on:click="combineSameWagetypes = !combineSameWagetypes"></i>
            </div>
          </div>
        </div>
      </div>
      <b-container fluid>
        <div class="full split-bottom">
          <div class="label left" v-if="loading">
            <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br />
            Daten werden geladen<br />
          </div>

          <div class="label left" v-if="!loading">
            <div v-if="!wageDataCurrentDisplay">
              <h4 style="margin-top:10px;margin-bottom:10px;">
                Es sind noch keine Lohndaten vorhanden
              </h4>
            </div>
            <div v-else v-for="store in wageDataCurrentDisplay">
              <h4 style="margin-top:10px;margin-bottom:10px;">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    {{ storeNameAndDescription(store.wagedata[0].storeId) }}
                    <i v-if="storeNameAndDescription(store.wagedata[0].storeId) === 'Keine Kostenstelle'" class="fa fa-info-circle" style="color:var(--ml)"
                       v-tooltip="'Um ebenfalls untenstehende Positionen zu den korrekten Kostenstellen zuweisen zu können, <br>' +
                      'exportieren Sie im Mitarbeiter-Kore Report ebenfalls die Kostenstelle im Feld \'Freifeld 09 Monat\'. <br>' +
                       'Mehr dazu auf der Hilfeseite \'Lohndaten importieren\''" />
                  </div>
                  <div v-if="storeNameAndDescription(store.wagedata[0].storeId) === 'Keine Kostenstelle'"
                       style="color:var(--ml)">
                    <i v-show="!editCostCenter && !combineSameWagetypes" class="fa fa-pen" v-on:click="editCostCenter = true" v-tooltip="'Kostenstellen zuordnen'" />
                    <i v-show="editCostCenter" class="fa fa-ban" v-on:click="resetStoreIds()" v-tooltip="'Abbrechen'" />
                    <i v-show="editCostCenter">&nbsp;&nbsp;</i>
                    <i v-show="editCostCenter" class="fa fa-floppy-disk" v-on:click="updateStoreIds()" v-tooltip="'Kostenstellen speichern'"/>
                  </div>
                </div>
              </h4>
              <table class="table table-striped table-borderless">
                <tr>
                  <th class="documentsTh" style="text-align:center;">Jahr</th>
                  <th class="documentsTh" style="text-align:center;">Monat</th>
                  <th class="documentsTh" style="text-align:center;">Lohnart</th>
                  <th class="documentsTh" style="text-align:center;">Menge</th>
                  <th class="documentsTh" style="text-align:center;">Betrag</th>
                  <th v-if="storeNameAndDescription(store.wagedata[0].storeId) === 'Keine Kostenstelle' && editCostCenter === true"
                      class="documentsTh" style="text-align:center;">Kostenstelle (Store)</th>
                </tr>
                <tr v-for="wagedata in store.wagedata">
                  <td class="documentsTd" style="text-align:center;">{{wagedata.year}}</td>
                  <td class="documentsTd" style="text-align:center;">{{getMonthName(wagedata.month)}}</td>
                  <td class="documentsTd" style="text-align:center;">{{getWageTypeName(wagedata.wageType)}}</td>
                  <td class="documentsTd" style="text-align:center;">{{wagedata.count / 100.0}}</td>
                  <td class="documentsTd" style="text-align:center;">€ {{ (wagedata.amount / 100.0).toFixed(2) }}</td>
                  <td v-if="storeNameAndDescription(wagedata.storeId) === 'Keine Kostenstelle' && editCostCenter === true"
                      class="documentsTd" style="text-align:center;">
                    <select v-model="wagedata.storeId_input">
                      <option
                          v-for="(entry, key) in storeDropdownItems"
                          :label="entry.label"
                          :value="entry.value"
                      >
                        {{ entry.label }}
                      </option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'employeewagedata',
  data() {
    return {
      loading: true,
      stores: {},
      wagedata: [],
      bmdWageTypes: [],
      editCostCenter: false,
      combineSameWagetypes: true,
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    //console.log("MOUNTED");
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser', 'externalServicesMap', 'storeSettings','storeArray',]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [
        ]
      };

      return state;
    },
    timeZoneList() {
      let result = [];
      /*
      this.timeZones.forEach(i => {
          let item = {label: i, value: i};
          result.push(item);
      })
      */
      return result;
    },
    wageDataCurrentDisplay() {
      let view = this;

      let year = view.$parent.timeframeStart.years();
      let month = view.$parent.timeframeStart.months() + 1;

      let storesCopy = JSON.parse(JSON.stringify(view.stores));

      let storedata = null;
      for (let i in storesCopy) {
        if (storesCopy[i].wagedata[year] && storesCopy[i].wagedata[year][month]) {
          if (!storedata) {
            storedata = {};
          }
          if (!storedata[i]) {
            storedata[i] = {};
          }
          storedata[i].wagedata = storesCopy[i].wagedata[year][month];

          if (view.combineSameWagetypes) {
            let combinedStoreData = {};
            storedata[i].wagedata.forEach(wt => {
              if (combinedStoreData.hasOwnProperty(wt.wageType)) {
                combinedStoreData[wt.wageType].count += wt.count;
                combinedStoreData[wt.wageType].amount += wt.amount;
              } else {
                combinedStoreData[wt.wageType] = wt;
              }
            });
            storedata[i].wagedata = Object.values(combinedStoreData);
          }
        }
      }
      return storedata;
    },
    storeDropdownItems() {
      let view = this;
      let storeItems = [];
      Object.values(view.wageDataCurrentDisplay).forEach(store => {
        storeItems.push({label: view.storeNameAndDescription(store.wagedata[0].storeId), value: store.wagedata[0].storeId});
      });
      return storeItems;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    employee: function (val, oldVal){
      this.loading = true;
      this.refresh();
    },
    refreshWageDataPage: function (val, oldVal){
      if (val) {
        this.$parent.refreshWageDataPage = false;
        this.loading = true;
        this.refresh();
      }
    },
    importWageData: function (val, oldVal){
      if (val) {
        this.$parent.importWageData = false;
        this.openCSVImportPopup();
      }
    }
  },
  props: [
    "employee",
    "refreshWageDataPage",
    "importWageData",
  ],
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      this.loading = true;
      let view = this;
      view.editCostCenter = false;
      view.stores = {};
      view.wagedata = [];
      view.loadEnum();
      view.getEmployeeWageData().then((response) => {
        view.wagedata = response.data.data.data;
        for(let i in view.wagedata){
          let obj = view.wagedata[i];
          obj.storeId_input = obj.storeId;
          if(view.stores[obj.storeId] == undefined){
            view.stores[obj.storeId] = {};
            view.stores[obj.storeId].wagedata = {};
          }
          if(!view.stores[obj.storeId].wagedata.hasOwnProperty(obj.year)) {
            view.stores[obj.storeId].wagedata[obj.year] = {};
          }
          if(!view.stores[obj.storeId].wagedata[obj.year].hasOwnProperty(obj.month)) {
            view.stores[obj.storeId].wagedata[obj.year][obj.month] = [];
          }
          view.stores[obj.storeId].wagedata[obj.year][obj.month].push(obj);
        }
        view.loading = false;
      }).catch(function (error){
        console.log(error);
        view.$helpers.error('Fehler', 'Lohndaten konnten nicht geladen werden');
      });
    },
    getEmployeeWageData: function(){
      let view = this;
      let baseUrl = '/api/sec/bmd/wageData';
      let url = baseUrl;

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("employeeId", view.employee.id, url, baseUrl);

      return this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      });
    },
    saveEmployeeWageData: function(filename, file, year, costCenterSetting) {
      let view = this;
      let baseUrl = '/api/sec/bmd/wageData';
      let url = baseUrl;

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("source", "bmd_ml", url, baseUrl);
      url = this.$helpers.appendParam("filename", filename, url, baseUrl);
      url = this.$helpers.appendParam("year", year, url, baseUrl);
      url = this.$helpers.appendParam("costCenterSetting", costCenterSetting, url, baseUrl);

      let payload = {
        "file": file
      }

      return this.axios({
        method: 'post',
        data: payload,
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      });
    },

    updateStoreIds() {
      let view = this;

      let wagedata = view.getCurrentEmptyStoreIdEntries();

      let baseUrl = '/api/sec/bmd/wageData/update';
      let url = baseUrl;

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      let payload = {
        "data": wagedata
      }

      this.axios({
        method: 'post',
        data: payload,
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      })
        .then((response) => {
          view.refresh();
        })
        .catch((error) => {
          console.log(error);
          view.resetStoreIds();
        })
        .finally((fin) => {
          view.editCostCenter = false;
        });
    },
    resetStoreIds() {
      let view = this;

      let wagedata = view.getCurrentEmptyStoreIdEntries();
      Object.values(wagedata).forEach(item => {
        item.storeId_input = item.storeId;
      });

      view.editCostCenter = false;
    },
    getCurrentEmptyStoreIdEntries() {
      let view = this;
      let emptyStoreId = view.stores[null];
      let year = Object.values(emptyStoreId)[0];
      let month = Object.values(year)[0];
      return Object.values(month)[0];
    },

    loadEnum() {
      let view = this;
      let url = '/api/enum/bmdwagetype';
      this.axios({
        method: 'get',
        headers: {},
        url: url,
      }).then(response => {
        view.bmdWageTypes = response.data;
      }).catch(function (error) {
        console.log(error);
      })
    },
    storeNameAndDescription(id){
      for(let i in this.storeArray){
        let s = this.storeArray[i];
        if(s.id == id){
          return s.name + " / " + s.description;
        }
      }
      return "Keine Kostenstelle";
    },
    getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString('de-at',{ month: 'long' });
    },
    getWageTypeName(wageType) {
      return Object.values(this.bmdWageTypes).find(wt => wt.key == wageType).valueGER;
    },

    openCSVImportPopup() {
      let view = this;

      let yearItems = [];
      let currentYear = new Date().getFullYear();
      for (let i = currentYear - 5; i <= currentYear + 1; i++) {
        yearItems.push({label: i.toString(), value: i});
      }

      let costCenterConfigItems = [
        {
          label: "Strikt",
          value: 0
        },
        {
          label: "Dynamisch",
          value: 1
        },
        {
          label: "Hauptstore",
          value: 2
        },
      ];

      let dataEntries = [
        {
          label: "CSV Datei",
          accept: ".csv",
          key: "file",
          hidden: false,
          type: "file",
          value: null,
        },
        {
          label: "Jahr der Abrechnung",
          key: "year",
          hidden: false,
          type: "dropdownsearch",
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: yearItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: {label: currentYear.toString(), value: currentYear},
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
        {
          label: "Kostenstelle",
          key: "costCenterSetting",
          hidden: false,
          type: "dropdownsearch",
          value: null,
          flexGroup: false,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: costCenterConfigItems,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: costCenterConfigItems[0],
            onceSelected: function ($event, item, searchbar) {
            },
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
      ];

      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        componentState: {
          entries: dataEntries,
          selectCallback: function (importf) {
            if (importf.file.length > 0) {
              let filename = importf.file[0].name;

              var reader = new FileReader();
              reader.readAsText(importf.file[0], "UTF-8");
              reader.onload = function () {
                //console.log(reader.result);
                view.saveEmployeeWageData(filename, btoa(reader.result), importf.year, importf.costCenterSetting)
                  .then(response => {
                    view.$helpers.success(
                        "Erfolg",
                        "Lohndaten Import wurde erfolgreich angestoßen. " +
                        "Das Update kann einige Sekunden dauern. " +
                        "Bitte beachten Sie, dass die Daten neu geladen werden müssen."
                    );
                  })
                  .catch(error => {
                    view.$helpers.error(
                        "Fehler",
                        "Lohndaten Import konnte nicht durchgeführt werden. " +
                        "Bitte prüfen Sie die hochgeladene Datei und versuchen Sie den Import erneut."
                    )
                  });
              };
              reader.onerror = function () {
                view.$helpers.error(
                    "Fehler",
                    "Ausgewählte Datei konnte nicht gelesen werden"
                );
              }
            } else {
              view.$helpers.error(
                  "Fehler",
                  "Es wurde keine Import Datei ausgewählt"
              );
            }
          },
        },
        header: "Lohndaten importieren",
        remainAfterSelect: false,
        expanded: true,
        centered: true,
      });
    },
  },

}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->

<style scoped>
a {
  color: var(--ml);
  cursor: pointer !important;
}

a:hover {
  color: var(--contrast-4);
  cursor: pointer !important;
}
</style>
